import React from 'react'
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import ImageGridList from '../components/Images/ImageGridList'

import { motion } from 'framer-motion'
import { pageVariants, pageTransition } from './animationVariants'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '2rem',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function FullWidthGrid() {
  const classes = useStyles();

  return (
  <>
    <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
          
      <Helmet>
        <title>Robin Howland | Profile images</title>
        <meta name='description' content='Robin Howland | Profile images'/>
      </Helmet>

      <Container className={classes.root}>
        <ImageGridList />
      </Container>

    </motion.div>
  </>
);
}