import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button'
import './navbar.css';

const Navbar = () => (
  <nav className="navbar">

    <Button
      exact
      color="inherit"
      component={NavLink}
      activeClassName="highlighted"
      className="navbarLink"
      to="/"
    >
      Actor
    </Button>

    <Button
      color="inherit"
      component={NavLink}
      activeClassName="highlighted"
      className="navbarLink"
      to="/images"
    >
      Images
    </Button>

    <Button
      color="inherit"
      component={NavLink}
      activeClassName="highlighted"
      className="navbarLink"
      to="/contact"
    >
      Contact
    </Button>
  </nav>
);

export default Navbar;