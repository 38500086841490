import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    //minWidth: 650,
  },  
});

export default function Films() {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">        
        {/* <TableHead>
          <TableRow>
            <TableCell>Experience</TableCell>
          </TableRow>
        </TableHead>  */}

        <TableBody>            
            <TableRow>              
              <TableCell>
                {/* <TableHead>Principle actor</TableHead>              */}
              Films:
              Eternal Muse, Echo, Where The Ivy Grows, Ruby, New Home Adventures<br/><br/>
              
              Docudrama: Catching Killers, Encounters, Hughie Green<br/>
              Commercials: Papaya Global, Soho Square, Derwent London, Maxwood<br/>
              Music Video: Henry Moodie - Drunk Text <br/><br/>

              Feature Films:
              Mission Impossible: Dead Reckoning (Italian lawyer restraining Tom Cruise) <br/>
              One Life (Family scenes with Anthony Hopkins)
              </TableCell>
            </TableRow>                   
        </TableBody>
       
            
        <TableBody>
            <TableRow>              
              <TableCell>Screen Combat: Take 3 Action Academy: 2 vs 1 - Street Open Armoury. British Acadamy of Dramatic Combat: Unarmed, Rapier & Dagger. Gun handling. Military reenactment</TableCell>              
            </TableRow>
            <TableRow>              
              <TableCell>Accents: RP (native), General American</TableCell>              
            </TableRow>
            <TableRow>              
              <TableCell>Location: West London</TableCell>              
            </TableRow>            
        </TableBody>


        <TableBody>            
            <TableRow>              
              <TableCell>
                
              <span style={{background:'#f5c518', color:'#000', fontWeight:'bold', padding:'0.1rem' }}>
                IMDb
              </span>
              &nbsp;&nbsp;

              <Link to={{ pathname: "https://imdb.me/RobinHowland" }} style={{ textDecoration:'underline', color:'#ffff', fontWeight:'bold' }} target="_blank" >
                  https://imdb.me/RobinHowland
              </Link>

                </TableCell>
            </TableRow>                   
        </TableBody>

      </Table>

    </TableContainer>

    
  );
}