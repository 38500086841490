// Tell webpack this JS file uses this image

import side from   './img/side.jpg';
//import TashHair from   './img/tash-long-hair.jpg';
//import blue from      './img/blue.jpg';
import pocket2 from    './img/pocket2.jpg';

import Overcoat from  './img/overcoat.jpg';
import Beard from     './img/beard.jpg';
import Tash from      './img/tash.jpg';
//import GreyT from      './img/greyT.jpg';
import musto from      './img/musto2.jpg';
import BlueS from      './img/blue-shirt.jpg';

import Pwr from      './img/poweroftherings.png';
import Red from      './img/red.jpg'; //1080 x 1350


export default [
  
  {  src: musto,     width: 1500,    height: 2000 }, 
  {  src: Overcoat,  width: 1500,    height: 2000 },
  {  src: Pwr,      width: 864,     height: 1360 },
  
  {  src: Red,        width: 701,    height: 934 },
  //{  src: pocket2,    width: 1500,    height: 2000 },

   
  {  src: BlueS,     width: 1500,    height: 2000 }, 
  {  src: Beard,     width: 1500,    height: 2000 },   
  {  src: Tash,      width: 1500,    height: 2000 },
  {  src: side,   width: 1500,    height: 2000 }

  //{  src: blue,      width: 900,     height: 1200 }  
  

  
];
