import React, { Component } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Route, Switch, useLocation } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline'; // global reset
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Images from './containers/Images';
import Home from './containers/Home';
import Contact from './containers/Contact';
import {AnimatePresence } from 'framer-motion'


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;


function App() {

  const location = useLocation()

   return (
    <>
    <CssBaseline />
    <GlobalStyle />  

    <ThemeProvider theme={theme}>
      <Header />

        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>          
            <Route exact path='/' component={Home} />
            <Route path='/images' component={Images} />
            <Route path='/contact' component={Contact} />

            {/* by not defineing the path route will always render
            equiv of 404, if url not recognised render home page (could replace Home with 404 component) */}
            <Route component={Home} />

          </Switch> 
        </AnimatePresence>

      <Footer />
    </ThemeProvider>    
  </>
  );
}

export default App;
