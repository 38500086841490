import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({

    formContainer: {
        //margin: '1rem',
        // apply to any nested container inside form container
        '& .MuiTextField-root': {
            margin: '1rem 0',
        }
    }

})