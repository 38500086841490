import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Navbar from '../Header/Navbar';
import { Container } from '@material-ui/core';

import Sxd from '../../systemxdesign.png'; 
import Spot from '../../spotlight.png'; 

const useStyles = makeStyles((theme) => ({
    root: {
      //flexGrow: 1,
      //background: '#444',
      textAlign: 'center',
      //marginTop: '10rem',     
      color: '#ccc',
      paddingTop: '2rem',
      paddingBottom: '2rem',

    },
    paper: {
      //padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    topM: {
        marginTop: '2rem',
      },
    aLink: {
      color: '#f2f2f2',
      textDecoration: 'none',      
    },    
  }));


// const Title = styled.h1`
//   pointer-events: none;
// `;

const dt = new Date().getFullYear()


export default function Footer () {
    const classes = useStyles()
    
    return(
  <>
     <div className={classes.root}>
      <Grid>
        <Grid item xs={12}>
            <Container>

              <p>
                <a href="https://www.spotlight.com/4938-8945-9186"> 
                <img src={Spot} alt='Robin Howland on Spotlight'/>
                </a>
              </p>

                <Navbar />

                <p className={classes.topM}>
                  <a href="cookies.pdf" target="_blank" className={classes.aLink}>Cookies policy</a><br/>
                      In viewing this site you accept the use of cookies
                </p>
                
                <p>
                    &copy; {dt} - All rights reserved
                </p>                
                {/* <p>
                  <a href="http://systemxdesign.com"> 
                    <img src={Sxd} alt='Site by: System X Design'/>
                  </a>
                </p> */}
          </Container>
        </Grid>        
      </Grid>
    </div>
  </>
    )
}

