export const pageVariants = { 
  in: {
    opacity: 1,
    //x: 0,
  },
  out: {
    opacity: 0,
    //x: '-100vw',
  }
};

//export const pageTransition = { 
  //transition : 'linear'

  // type: 'tween',
  // ease: 'linear',
  // duration: 2

//};

// export const pageVariants = { 
//   // animate
//   in: {
//     opacity: 1,    
//   },
//   // initial
//   out: {
//     opacity: 0,
//     y: 200,
//   },
//   exit: {
//     opacity: 0, 
//   }
// };

export const pageTransition = { 
  //transition : 'linear'

  // type: 'tween',
  // ease: 'linear',
   //duration: 0.3

};


