import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import useStyles from './styles'
import axios from 'axios';
import Typography from '@material-ui/core/Typography';

const ContactSchema = yup.object().shape({
  firstname: yup
  .string()
  .trim()
  .required()
  .min(2)
  .max(40)
  .matches(/^([a-zA-Z-]+)$/, 'no special characters, numbers or spaces'),
  lastname: yup
  .string()
  .trim()
  .required()
  .min(2)
  .max(40)
  .matches(/^([a-zA-Z-]+)$/, 'no special characters, numbers or spaces'),
   email: yup
  .string()
  .trim()
  .required('enter a valid email')
  .required()
  .email(),
  message: yup
  .string()
  .trim()
  .min(10)
  .max(200)
  .required()
  .matches(/^([a-zA-Z 0-9_,.-]+)$/, 'no special characters, alpharnumeric only'),
})

export default function ContactForm() {

  // const [msgSent, setMsgSent] = useState(false);

  // const getData = (data) => {    
  //   let params = new URLSearchParams();
  //   params.append('firstname', data.firstname );
  //   params.append('lastname', data.lastname );
  //   params.append('email', data.email );
  //   params.append('message', data.message );
      
  //   axios.post('http://robhowland.com/contact.php', params)
  //   .then(function (response) {
  //     // handle success
  //     console.log(response.data+'.');
  //     if( (response.data).trim()  === 'success') {
  //       console.log('it matched')
  //       // SET STATE TO REM FORM AND DISPLAY MESSAGE 
  //       setMsgSent(true)
  //       reset()
  //     } else {
  //       console.log('no match')
  //     }
  //   })
  //   .catch(function (error) {
  //     // handle error
  //     console.log(error);
  //   })
  //   .then(function () {
  //     // always executed
  //   });  
  // }
  
  const classes = useStyles();

  const { register, handleSubmit, errors, reset, formState } = useForm({    
     validationSchema: ContactSchema,
     mode: 'onChange' // used with formState to disable btn if form state not valid
  })

  // const onSubmit = (data, e) => {    
  //   e.target.reset()
  //   getData(data)
  // }

  return (
    <>
    {/* {msgSent}
      {msgSent === true &&
        <Typography gutterBottom variant="h6">Thank you, your message has been sent</Typography>
      } */}
   
    
    <form noValidate className={classes.formContainer} method='post'>
      <div>
      <TextField
        variant='outlined' required label="First name" name='firstname' inputRef={register} 
        error={errors.firstname} helperText={errors.firstname ? errors.firstname.message : ''}
      />        
      </div>  
      <div> 
      <TextField
        variant='outlined' required label="Last name" name='lastname' inputRef={register} 
        error={errors.lastname} helperText={errors.lastname ? errors.lastname.message : ''}
        />
      </div>
      <div> 
      <TextField 
        variant='outlined' required label="Email" name='email' inputRef={register} 
        error={errors.email} helperText={errors.email ? errors.email.message : ''} 
        fullWidth
        />             
     </div> 
     <div> 
        <TextField 
          inputRef={register}
          variant='outlined'id="standard-multiline-flexible" label="Message"   name='message'
          multiline  rows="7"  required  error={errors.message}  helperText={errors.message ? errors.message.message : ''} fullWidth                  
        />
      </div> 

      <input type="hidden" name="form-name" value="contact" />

      <div><Button variant="contained" color="primary" type='submit' disabled={!formState.isValid} >Submit</Button></div>  
    </form>

    </>

  );
}
