import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import JustifiedGrid from 'react-justified-grid'; // npm install --save react-justified-grid
import mockData from './mockData';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      marginTop: '2rem',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


 export default function ImageGridList() {
  const classes = useStyles();
  return (
   <div className={classes.root}>

        <JustifiedGrid
          images={mockData}
          rows={8}
          maxRowHeight={450}
          showIncompleteRow={false}
          gutter={5}
        />
        {/* <hr />
        <JustifiedGrid images={mockData} rows={2} maxRowHeight={128}>
          {processedImages => (
            <React.Fragment>
              {processedImages.map((image, i) => {
                const { alt, src, width, height, left, top } = image;
                return (
                  <a
                    key={i}
                    href="https://stackla.com"
                    style={{
                      position: 'absolute',
                      left: left,
                      top: top
                    }}>
                    <img src={src} width={width} height={height} alt={alt} />
                  </a>
                );
              })}
            </React.Fragment>
          )}
        </JustifiedGrid>   */}
      
    </div> 
  );
}