import React from 'react'
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Profile from '../components/Home/Profile'
import ProfileImg from '../components/Home/ProfileImg'
import Films from '../components/Home/Films'
import Hidden from '@material-ui/core/Hidden';
import { motion } from 'framer-motion'
import { pageVariants, pageTransition } from './animationVariants'
import Banner from '../components/Home/Banner';
import Video from '../components/Home/Video';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '2rem',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }, 
}));


export default function FullWidthGrid() {
  const classes = useStyles();

  return (
  <>

  <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
    <Helmet>
      <title>Robin Howland | Actor</title>
      <meta name='description' content='Robin Howland | Actor'/>
    </Helmet>

    <Container className={classes.root}>
      <div className={classes.root}>
        
        <Grid container spacing={2}>
          
          {/* <Grid item xs={12} sm={4} md={3}>
            <ProfileImg />
          </Grid> */}

          <Grid item xs={12} sm={12} md={6}>
            {/* <Profile /> */}
            <Video />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Films />
          </Grid>

          {/* 
          Banner re. casting directors
          <Hidden mdDown>
          <Grid item xs={12}>
            <Banner />
          </Grid>
          </Hidden> */}

        </Grid>

      </div>    
    </Container>

  </motion.div>

  </>
);
}