import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import { NavLink } from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    //minWidth: 650,
  },
  banner: {
    textAlign: 'center',
  },
});


export default function Banner() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell>Experience</TableCell>
          </TableRow>
        </TableHead> */}
        
        <TableBody>         
            <TableRow>              
              <TableCell className={classes.banner}>Contact from casting agents welcome</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
