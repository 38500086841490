import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import TemporaryDrawer from './TemporaryDrawer'
import Navbar from './Navbar';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  menuButton: {
    //marginRight: theme.spacing(2),
  },
  navLink: {
    color: '#fff',
    paddingLeft: '1rem',   
    textDecoration: 'none',
    textTransform: 'uppercase',    
  },
  activeClassName: {
    color: '#000',
  },  
  title: {
    flexGrow: 1,
    color: '#cccccc',
    textDecoration: 'none',
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger // will default to window.  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**  * Injected by the documentation to work in an iframe.  * You won't need it on your project.   */
  window: PropTypes.func,
};

export default function ElevateAppBar(props) {

  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
       
        <AppBar style={{ background: '#242424' }}>
          
          <Container>  

            <Toolbar>

              <Typography variant="h5" className={classes.title} component={NavLink} exact to="/">
                ROBIN HOWLAND
              </Typography>

              <Hidden smDown>
                <Navbar/>                
              </Hidden>

              <Hidden mdUp>
                <TemporaryDrawer /> 
              </Hidden>

            </Toolbar>
            
          </Container>

        </AppBar>
      </ElevationScroll>
      <Toolbar />      
    </React.Fragment>
  );
}
