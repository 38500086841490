import React from 'react'
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ContactForm from '../components/Contact/ContactForm'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { motion } from 'framer-motion'
import { pageVariants, pageTransition } from './animationVariants';

import Beard from '../components/Images/img/beard.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '2rem',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function FullWidthGrid() {
  const classes = useStyles();

  return (
  <>
  <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
  
    <Helmet>
      <title>Robin Howland | Contact</title>
      <meta name='description' content='Robin Howland | West London | Actor'/>
    </Helmet>

    <Container className={classes.root}>
    <div className={classes.root}>
      <Grid container spacing={2}>        
        
        
        <Grid item xs={12} sm={6} md={5} >         
          <Card>
              <CardMedia
                component="img"
                alt="Contact Robin Howland"
                //height="542"
                height="543"
                image={Beard}
                title="Contact Robin Howland"
              />
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={7} >
          <Card>
            <CardContent>
              <ContactForm />
            </CardContent>
          </Card>
        </Grid>


      </Grid>
    </div>
    </Container>

  </motion.div>

  </>
);
}