import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    //minWidth: 650,
  },
});



export default function Profile() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Rob Howland, Actor</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>         
            <TableRow>              
              <TableCell>West London, UK</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Eyes: green</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Hair: dark brown</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Height: 5,9"</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Weight: 11st</TableCell>
            </TableRow>
            {/* <TableRow>              
              <TableCell>Build: athletic</TableCell>
            </TableRow> */}
            <TableRow>              
              <TableCell>Ethnicity: caucasian</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Accents: English Southern/Home Counties (native), General American</TableCell>
            </TableRow>
            {/* <TableRow>              
              <TableCell>Accents: American Southern/General</TableCell>
            </TableRow> */}
            <TableRow>              
              <TableCell>Playing age: 35 - 45</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>DBS checked</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Full (clean) driving/motorcycle licence</TableCell>
            </TableRow>
            

        </TableBody>
      </Table>
    </TableContainer>
  );
}
