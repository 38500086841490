import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


//import blkShirt from '../Images/img/blk-shirt.jpg';


const useStyles = makeStyles({
  root: {
    //maxWidth: 345,
  },
  title: {
    fontSize: '0.99rem',
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: '2rem',
  },
  smalltitle: {
    fontSize: '0.94rem',
    lineHeight: '2rem',
    //fontWeight: 'bold',
    //fontStyle: 'italic',
  },
});

export default function ProfileImg() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {/* <CardMedia
          component="img"
          alt="London male actor"
          height="390"
          image={blkShirt}
          title="Actor headshot"
        /> */}
      <CardContent> 
        
        <Typography className={classes.smalltitle}> 
         {/* <hr />      */}
        'Robin, I want you to be in this movie'
        {/* <br /> */}
        </Typography>
        <Typography className={classes.smalltitle}>  
        -Christopher McQuarrie
        {/* <br /><hr /><br /> */}
        </Typography>

        {/* <Typography className={classes.smalltitle}>  
        Coached for film/TV
        </Typography>  */}


      </CardContent> 
      </CardActionArea>
 
      {/* {'"Robin, I want you to be in this movie"'} 
        
        {/* <CardContent>
          <Typography gutterBottom variant="h6">
          Seeking roles in film/TV
          </Typography>          
          <Typography paragraph component="p">
          Contact from casting directors/talent agents welcome                  
          </Typography>
          <Typography paragraph component="p">         
          Privately coached for film/TV      
          </Typography>
        </CardContent> 

         <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="h2">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          adjective
        </Typography>
        <Typography variant="body2" component="p">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent> */}
    </Card>

    



  ); 
}


